import Vue from "vue";
import Vuex from "vuex";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

import potensiKampungModules from "./modules/potensiKampung";
import repositoryModules from "./modules/repository";

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    potensiKampung: potensiKampungModules,
    repository: repositoryModules,
  },
  strict: process.env.NODE_ENV !== "production",
});
