 

const mutations = { 
	SET_BG(state, val) {
		state.bgSetting = val
	},
	DISTRICT_STATE(state, payload) {
		state.districtstate = payload
	} 
}

export default mutations