<template>
  <div class="home">
    <slick ref="slick" class="slickHome" :options="slickOptions">
      <!-- <div class="slick-content-home">
        <div
          class="sub-content-slider"
          :style="{
            'background-image':
              'url(' +
              require('../assets/images/background/banner-happy-new-year-saik+2.png') +
              ')',
          }"
        >
          <div class="content-text">
            <span
              class="d-block font-40 font-res-30 text-danger opensans font-weight-6"
              >KEINDAHAN ALAM PAPUA BARAT</span
            >
            <span class="d-block font-20 text-white opensans"
              >Selasa 17 Januari 2021, 11:36 WIB</span
            >
          </div>
        </div>
      </div> -->
      <div class="slick-content-home">
        <div
          class="sub-content-slider"
          :style="{
            'background-image':
              'url(' +
              require('../assets/images/background/banner-jaga-kesehatan.png') +
              ')',
          }"
        >
          <div class="content-text">
            <!-- <span
              class="d-block font-40 font-res-30 text-danger opensans font-weight-6"
              >KEINDAHAN ALAM PAPUA BARAT</span
            >
            <span class="d-block font-20 text-white opensans"
              >Selasa 17 Januari 2021, 11:36 WIB</span
            > -->
          </div>
        </div>
      </div>
      <div class="slick-content-home">
        <div
          class="sub-content-slider"
          :style="{
            'background-image':
              'url(' +
              require('../assets/images/background/pembangunan.png') +
              ')',
          }"
        >
          <div class="content-text">
            <span
              style="margin-top:50px;"
              class="d-block font-40 font-res-30 text-white opensans font-weight-6"
              >Kunker Perdana Di Pegaf, Pj. Gubernur Ali Baham Ajak Semua Pihak
              Bersatu Dalam Gerakan Pembangunan</span
            >
            <a
              href="https://papuabaratprov.go.id/web/home/detail-news?id=4ET0PSJAC-Z8ds1dkOBMjUczQr-yVQFI"
              class=" font-20 text-white opensans"
            >
              Selengkapnya
            </a>
          </div>
        </div>
      </div>
      <div class="slick-content-home">
        <div
          class="sub-content-slider"
          :style="{
            'background-image':
              'url(' +
              require('../assets/images/background/raja-ampat.jpg') +
              ')',
          }"
        >
          <div class="content-text">
            <span
              style="margin-top:100px;"
              class="d-block font-40 font-res-30 text-white opensans font-weight-6"
              >KEINDAHAN ALAM PAPUA BARAT, Raja Ampat</span
            >
            <span class="d-block font-20 text-white opensans"
              >Selasa 17 Januari 2021, 11:36 WIB</span
            >
          </div>
        </div>
      </div>
    </slick>
    <div class="container-standar bg-white pl-10 pr-10">
      <section class="mb-40 pos-relative-anigram">
        <div class="row">
          <div class="col-lg-12 mb-20">
            <div class="card shadow">
              <div class="row">
                <div
                  class="col-md-3 d-inline-flex pad-10 text-center margin-auto"
                >
                  <div class="pad-20 w-full pos-respossive">
                    <div class="w-100 margin-auto text-center">
                      <img
                        class="margin-auto width-75 text-center"
                        src="@/assets/images/icon/icon-data.svg"
                        alt
                      />
                    </div>
                    <h4
                      class="font-20 text-center font-semibold mt-4 ml-3 text-primary"
                    >
                      Total Jiwa
                      <br />
                      <strong>{{ total_jiwa | convertToRupiah }}</strong>
                    </h4>
                  </div>
                </div>
                <div
                  class="col-md-3 d-inline-flex pad-10 text-center margin-auto"
                >
                  <div class="pad-20 w-full pos-respossive">
                    <div class="w-100 margin-auto text-center">
                      <img
                        class="margin-auto width-75 text-center"
                        src="@/assets/images/icon/icon-laki.svg"
                        alt
                      />
                    </div>
                    <h4
                      class="text-center font-20 font-semibold mt-4 ml-3 text-primary"
                    >
                      Total Laki Laki
                      <br />
                      <strong>{{ total_pria | convertToRupiah }}</strong>
                    </h4>
                  </div>
                </div>
                <div
                  class="col-md-3 d-inline-flex pad-10 text-center margin-auto"
                >
                  <div class="pad-20 w-full pos-respossive">
                    <div class="w-100 margin-auto text-center">
                      <img
                        class="margin-auto width-75 text-center"
                        src="@/assets/images/icon/icon-perempuan.svg"
                        alt
                      />
                    </div>
                    <h4
                      class="text-center font-20 font-semibold mt-4 ml-3 text-primary"
                    >
                      Total Perempuan
                      <br />
                      <strong>{{ total_perempuan | convertToRupiah }}</strong>
                    </h4>
                  </div>
                </div>
                <div
                  class="col-md-3 d-inline-flex pad-10 text-center margin-auto"
                >
                  <div class="pad-20 w-full pos-respossive">
                    <div class="w-100 margin-auto text-center">
                      <img
                        class="margin-auto width-75 text-center"
                        src="@/assets/images/icon/icon-jiwa.svg"
                        alt
                      />
                    </div>
                    <h4
                      class="text-center font-20 font-semibold mt-4 ml-3 text-primary"
                    >
                      Total KK
                      <br />
                      <strong>{{ total_kk | convertToRupiah }}</strong>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 d-inline-flex">
            <div class="shadow card pad-12 w-100">
              <span
                class="font-18 font-res-16 text-primary text-center pl-4 mb-10 font-weight-6"
              >
                <strong>PENDUDUK SESUAI USIA </strong>
              </span>
              <!-- <span class="flex justify-center mb-10 w-100">
                <selecYears class="width-80"></selecYears>
              </span> -->
              <bar-detail></bar-detail>
            </div>
          </div>
          <div class="col-md-8 d-inline-flex">
            <div class="card w-full shadow">
              <span
                class="font-18 font-res-16 text-primary text-center mt-10 pl-4 mb-10 font-weight-6"
              >
                <strong>
                  PENDUDUK PAPUA BARAT
                  <br />OAP/Non-OAP
                </strong>
              </span>
              <!-- <span class="flex justify-center mb-10 w-100">
                <selecYears class="col-xs-100 width-80"></selecYears>
              </span> -->
              <!-- <donutchart></donutchart> -->
              <grapichpenduduk class="w-full"></grapichpenduduk>
            </div>
          </div>
        </div>
      </section>
      <section class="pb-20 mt--res-80">
        <div class="container">
          <div class="col col-md-12 text-center mb-10">
            <span
              class="d-block text-primary font-35 font-res-28 font-weight-7 roboto"
              >LAYANAN SAIK +</span
            >
          </div>
          <div class="row">
            <div class="col-md-6">
              <span
                class="font-30 font-res-20 font-weight-7 d-block roboto mb-4 mb-res-10 res-text"
                >Apa itu SAIK + ?</span
              >
              <span class="font-20 text-grey d-block mb-40"
                >Sistem Aplikasi SAIK+ adalah Aplikasi penjaring data kampung
                dan kelurahan di seluruh wilayah Papua Barat yang berfungsi
                untuk meningkatkan kualitas pengelolaan data dan informasi dasar
                kampung sehingga dapat mendukung administrasi pemerintahan,
                perencanaan pembangunan, peningkatan layanan dasar, dan
                pengembangan kegiatan ekonomi kampung.</span
              >
              <!-- <span
                class="bg-primary pt-2 pb-2 opensans font-weight-6 text-white w-50 text-center btn-responsive-home d-block cursor-pointer font-20 border-radius-5"
              >
                Lihat Detail
                <i class="fa ml-2 fa-arrow-right"></i>
              </span>-->
            </div>
            <div class="col-md-6 text-center margin-auto">
              <img
                class="margin-auto"
                src="@/assets/images/misc/layanan.png"
                alt
              />
            </div>
          </div>
        </div>
      </section>
      <section class="mb-80">
        <div class="container">
          <div class="row justify-center">
            <div class="col col-md-12 text-center mb-20">
              <span
                class="d-block text-primary font-35 font-res-28 mb-res-10 font-weight-7 roboto"
                >BERITA SAIK+</span
              >
              <span class="d-block text-grey font-20"
                >Article Berita Seputar SAIK+ dan lainya</span
              >
            </div>
            <template v-for="(berita, index) in news">
              <router-link :to="`berita/${berita.id}`" v-bind:key="index">
                <figure class="snip1253">
                  <div class="image">
                    <img :src="berita.picture_path" alt="image" />
                  </div>
                  <figcaption>
                    <div class="date">
                      <span class="day">{{
                        berita.publish_date | publish_date_news("date")
                      }}</span>
                      <span class="month">{{
                        berita.publish_date | publish_date_news("month")
                      }}</span>
                    </div>
                    <h3>{{ berita.title }}</h3>
                    <p>{{ berita.content | shotDescription }}</p>
                  </figcaption>
                </figure>
              </router-link>
            </template>

            <div class="col-md-12 text-center mt-10">
              <router-link :to="'/berita'">
                <span
                  class="bg-primary pt-2 pb-2 btn-responsive-home opensans font-weight-6 text-white margin-auto w-50 text-center d-block cursor-pointer font-20 border-radius-5"
                >
                  Lihat Semua Berita
                  <i class="fa ml-2 fa-arrow-right"></i>
                </span>
              </router-link>
            </div>
          </div>
        </div>
      </section>
      <section class="pb-20 aplikasi-home mt--res-40">
        <div class="col col-md-12 text-center">
          <span
            class="d-block text-primary font-35 font-res-28 font-weight-7 roboto mb-2"
            >Aplikasi SAIK +</span
          >
          <span class="d-block text-grey font-20 font-res-18"
            >Aplikasi SAIK + Memudahkan anda</span
          >
        </div>
        <div class="row mt-80">
          <div class="col-md-6 mt-10 w-res-tab-100">
            <div class="content-video text-res-center margin-auto">
              <img src="@/assets/images/misc/rect.png" alt />
              <video width="500" controls>
                <source src="@/assets/images/video/saik.mp4" type="video/mp4" />
                Your browser does not support HTML5 video.
              </video>
            </div>
          </div>
          <div class="col-md-6 w-res-tab-100 mt-res-20">
            <span
              class="font-30 font-res-25 mb-res-10 roboto font-weight-6 d-block text-res-center margin-auto"
              >Apa itu Aplikasi SAIK + ?</span
            >
            <span
              class="d-block font-20 text-grey mt-2 w-70 text-res-center margin-auto"
            >
              Aplikasi SAIK + terdiri dari installer dan tata cara untuk
              mengunakan aplikasi sesuai dengan SOP yang sudah dibuat :
            </span>
            <div class="row mt-8">
              <!-- <div class="col-md-6 mb-15">
                <span
                  class="w-full d-block btn-pos-img cursor-pointer p-3 border-radius-5 font-weight-6 text-center text-primary shadow"
                >
                  <img src="@/assets/images/icon/log.png" alt /> Istilah dalam
                  SAIK
                </span>
              </div>
              <div class="col-md-6 mb-15">
                <span
                  class="w-full d-block btn-pos-img cursor-pointer p-3 border-radius-5 font-weight-6 text-center text-primary shadow"
                >
                  <img src="@/assets/images/icon/data.png" alt /> Dokumentasi
                  SAIK
                </span>
              </div> -->
              <br />
              <div class="col-md-12 mb-15">
                <router-link to="/bantuan/dokumentasi">
                  <span
                    class="w-full d-block btn-pos-img cursor-pointer p-3 border-radius-5 font-weight-6 text-center text-primary shadow"
                  >
                    <img src="@/assets/images/icon/doc.png" alt /> Dokumentasi
                    SAIK
                  </span>
                </router-link>
              </div>
              <!-- <div class="col-md-6 mb-15">
                <span
                  class="w-full d-block btn-pos-img cursor-pointer p-3 border-radius-5 font-weight-6 text-center text-primary shadow"
                >
                  <img src="@/assets/images/icon/log.png" alt /> Data Changelog
                </span>
              </div>-->
              <!-- <div class="col-md-12 text-center mt-10">
                <span
                  class="bg-primary pt-2 pb-2 btn-responsive-home opensans font-weight-6 text-white margin-auto w-50 text-center d-block cursor-pointer font-20 border-radius-5"
                >
                  Lihat Detail
                  <i class="fa ml-2 fa-arrow-right"></i>
                </span>
              </div>-->
            </div>
          </div>
        </div>
      </section>
      <section class="pb-100 pt-20 mt--res-100 bg-section-last">
        <div class="col col-md-12 text-center mb-20">
          <span
            class="d-block text-primary font-35 font-res-28 font-weight-7 roboto"
            >STATISTIC PROGRES DATA</span
          >
        </div>
        <div class="container">
          <div class="row justify-content-center margin-auto">
            <div class="width-20 data-progres">
              <span
                @click="tabbingChange(1)"
                :class="
                  tabnum == 1
                    ? ' shadow-md cursor-pointer m-2  border-solid-1 bc-primary bg-white font-14 text-center text-primary d-block p-2 font-weight-6 border-radius-5 is-active '
                    : 'shadow-md cursor-pointer m-2  border-solid-1 bc-primary bg-white font-14 text-center text-primary d-block p-2 font-weight-6 border-radius-5  '
                "
                >Kependudukan</span
              >
            </div>
            <div class="width-14 data-progres" @click="tabbingChange(2)">
              <span
                :class="
                  tabnum == 2
                    ? ' shadow-md cursor-pointer m-2  border-solid-1 bc-primary bg-white font-14 text-center text-primary d-block p-2 font-weight-6 border-radius-5 is-active '
                    : 'shadow-md cursor-pointer m-2  border-solid-1 bc-primary bg-white font-14 text-center text-primary d-block p-2 font-weight-6 border-radius-5  '
                "
                >Kesehatan</span
              >
            </div>
            <div class="width-14 data-progres" @click="tabbingChange(3)">
              <span
                :class="
                  tabnum == 3
                    ? ' shadow-md cursor-pointer m-2  border-solid-1 bc-primary bg-white font-14 text-center text-primary d-block p-2 font-weight-6 border-radius-5 is-active '
                    : 'shadow-md cursor-pointer m-2  border-solid-1 bc-primary bg-white font-14 text-center text-primary d-block p-2 font-weight-6 border-radius-5  '
                "
                >Pendidikan</span
              >
            </div>
            <div class="width-14 data-progres" @click="tabbingChange(4)">
              <span
                :class="
                  tabnum == 4
                    ? ' shadow-md cursor-pointer m-2  border-solid-1 bc-primary bg-white font-14 text-center text-primary d-block p-2 font-weight-6 border-radius-5 is-active '
                    : 'shadow-md cursor-pointer m-2  border-solid-1 bc-primary bg-white font-14 text-center text-primary d-block p-2 font-weight-6 border-radius-5  '
                "
                >Bansos</span
              >
            </div>
          </div>

          <div class="row mt-10" v-if="tabnum == 1">
            <div class="col-md-4 w-res-tab-100">
              <div class="grafix-donut bg-white shadow pt-55 pb-55">
                <div class="col-md-12 text-center">
                  <span class="font-23 font-weight-7 mb-20 d-block"
                    >GRAFIK PENDUDUK</span
                  >
                </div>
                <vue-apex-charts
                  type="donut"
                  class="zoom-res-8"
                  height="350"
                  :options="donutChart1.chartOptions"
                  :series="donutChart1.series"
                ></vue-apex-charts>
              </div>
            </div>
            <div class="col-md-8 w-res-tab-100">
              <div class="grafix-donut pt-55 pb-55">
                <div class="col-md-12 text-center">
                  <span class="font-23 font-weight-7 mb-20 d-block"
                    >DATA PENDUDUK</span
                  >
                </div>

                <div class="row">
                  <div
                    class="col-md-12 shadow bg-white p-3 border-radius-5 flex mb-20"
                  >
                    <span
                      class="font-20 font-res-14 text-primary d-inline-flex justify-start margin-auto font-weight-6"
                      >Penduduk Laki Laki</span
                    >
                    <span
                      class="font-20 font-res-14 text-grey d-inline-flex justify-end margin-auto font-weight-6"
                      >{{ total_penduduk_pria | convertToRupiah }} Orang</span
                    >
                  </div>
                  <div
                    class="col-md-12 shadow bg-white p-3 border-radius-5 flex mb-20"
                  >
                    <span
                      class="font-20 font-res-14 text-primary d-inline-flex justify-start margin-auto font-weight-6"
                      >Penduduk Perempuan</span
                    >
                    <span
                      class="font-20 font-res-14 text-grey d-inline-flex justify-end margin-auto font-weight-6"
                      >{{
                        total_penduduk_perempuan | convertToRupiah
                      }}
                      Orang</span
                    >
                  </div>
                  <div class="col-md-12 flex mb-20">
                    <router-link
                      to="/data-kependudukan"
                      class="bg-primary pt-2 pb-2 opensans font-weight-6 text-white justify-center flex w-50 w-res-100 text-center margin-auto cursor-pointer font-20 border-radius-5"
                    >
                      Lihat Detail
                      <i class="fa ml-2 mt-2 fa-arrow-right"></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-10" v-if="tabnum == 2">
            <div class="col-md-6 w-res-tab-100 w-res-100 ">
              <div class="card shadow">
                <div class="w-100">
                  <h2
                    class="text-center font-23 pt-10 pb-10 font-weight-7 roboto text-upper    "
                  >
                    Sumber Air Minum
                  </h2>
                </div>
                <SumberAirMinum></SumberAirMinum>
              </div>
            </div>
            <div class="col-md-6 w-res-tab-100">
              <div class="grafix-donut pt-55 pb-55">
                <div class="col-md-12 text-center">
                  <span class="font-23 font-weight-7 mb-20 d-block"
                    >DATA JIWA OAP dan Non OAP</span
                  >
                </div>

                <div class="row">
                  <div
                    class="col-md-12 shadow bg-white p-3 border-radius-5 flex mb-20"
                  >
                    <span
                      class="font-20 font-res-14 text-primary d-inline-flex justify-start margin-auto font-weight-6"
                      >Total Jiwa OAP
                    </span>
                    <span
                      class="font-20 font-res-14 text-grey d-inline-flex justify-end margin-auto font-weight-6"
                      >{{ totalForData.jiwa.oap | convertToRupiah }} Orang</span
                    >
                  </div>
                  <div
                    class="col-md-12 shadow bg-white p-3 border-radius-5 flex mb-20"
                  >
                    <span
                      class="font-20 font-res-14 text-primary d-inline-flex justify-start margin-auto font-weight-6"
                      >Total Jiwa Non OAP</span
                    >
                    <span
                      class="font-20 font-res-14 text-grey d-inline-flex justify-end margin-auto font-weight-6"
                      >{{
                        (totalForData.jiwa.semua - totalForData.jiwa.oap)
                          | convertToRupiah
                      }}
                      Orang</span
                    >
                  </div>
                  <div
                    class="col-md-12 shadow bg-white p-3 border-radius-5 flex mb-20"
                  >
                    <span
                      class="font-20 font-res-14 text-primary d-inline-flex justify-start margin-auto font-weight-6"
                      >Total Semua Jiwa</span
                    >
                    <span
                      class="font-20 font-res-14 text-grey d-inline-flex justify-end margin-auto font-weight-6"
                      >{{
                        totalForData.jiwa.semua | convertToRupiah
                      }}
                      Orang</span
                    >
                  </div>
                  <div class="col-md-12 flex mb-20">
                    <router-link
                      to="/data-kesehatan"
                      class="bg-primary pt-2 pb-2 opensans font-weight-6 text-white justify-center flex w-50 w-res-100 text-center margin-auto cursor-pointer font-20 border-radius-5"
                    >
                      Lihat Detail
                      <i class="fa ml-2 mt-2 fa-arrow-right"></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-10" v-if="tabnum == 3">
            <div class="col-md-12 w-res-tab-100 w-res-100 ">
              <div class="card shadow">
                <div class="w-100">
                  <h2
                    class="text-center font-23 pt-10 pb-10 font-weight-7 roboto text-upper    "
                  >
                    Data Pendiikan Tahun 2022
                  </h2>
                </div>
                <TingkatPendidikan></TingkatPendidikan>
              </div>
              <div class="col-md-12 flex mb-20  mt-10">
                <router-link
                  to="/data-pendidikan"
                  class="bg-primary pt-2 pb-2 opensans font-weight-6 text-white justify-center flex w-50 w-res-100 text-center margin-auto cursor-pointer font-20 border-radius-5"
                >
                  Lihat Detail
                  <i class="fa ml-2 mt-2 fa-arrow-right"></i>
                </router-link>
              </div>
            </div>
            <!-- <div class="col-md-6 w-res-tab-100">
              <div class="grafix-donut pt-55 pb-55">
                <div class="col-md-12 text-center">
                  <span class="font-23 font-weight-7 mb-20 d-block"
                    >DATA JIWA OAP dan Non OAP</span
                  >
                </div>

                <div class="row">
                  <div
                    class="col-md-12 shadow bg-white p-3 border-radius-5 flex mb-20"
                  >
                    <span
                      class="font-20 font-res-14 text-primary d-inline-flex justify-start margin-auto font-weight-6"
                      >Total Jiwa OAP </span
                    >
                    <span
                      class="font-20 font-res-14 text-grey d-inline-flex justify-end margin-auto font-weight-6"
                      >{{ totalForData.jiwa.oap | convertToRupiah }} Orang</span
                    >
                  </div>
                  <div
                    class="col-md-12 shadow bg-white p-3 border-radius-5 flex mb-20"
                  >
                    <span
                      class="font-20 font-res-14 text-primary d-inline-flex justify-start margin-auto font-weight-6"
                      >Total Jiwa Non OAP</span
                    >
                    <span
                      class="font-20 font-res-14 text-grey d-inline-flex justify-end margin-auto font-weight-6"
                      >{{
                       totalForData.jiwa.semua -  totalForData.jiwa.oap  | convertToRupiah
                      }}
                      Orang</span
                    > 
                  </div>
                    <div
                    class="col-md-12 shadow bg-white p-3 border-radius-5 flex mb-20"
                  >
                    <span
                      class="font-20 font-res-14 text-primary d-inline-flex justify-start margin-auto font-weight-6"
                      >Total Semua Jiwa</span
                    >
                    <span
                      class="font-20 font-res-14 text-grey d-inline-flex justify-end margin-auto font-weight-6"
                      >{{
                       totalForData.jiwa.semua   | convertToRupiah
                      }}
                      Orang</span
                    > 
                  </div>
                  <div class="col-md-12 flex mb-20">
                    <span
                      class="bg-primary pt-2 pb-2 opensans font-weight-6 text-white justify-center flex w-50 w-res-100 text-center margin-auto cursor-pointer font-20 border-radius-5"
                    >
                      Lihat Detail
                      <i class="fa ml-2 mt-2 fa-arrow-right"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
          <div class="row mt-10" v-if="tabnum == 4">
            <div class="col-md-12 w-res-tab-100 w-res-100 ">
              <div class="card shadow">
                <div class="w-100">
                  <h2
                    class="text-center font-23 pt-10 pb-10 font-weight-7 roboto text-upper    "
                  >
                    Data Bantuan Sosial Tahun 2022
                  </h2>
                </div>
                <BanSos></BanSos>
              </div>
              <div class="col-md-12 flex mb-20  mt-10">
                <router-link
                  to="/data-bantuan-sosial"
                  class="bg-primary pt-2 pb-2 opensans font-weight-6 text-white justify-center flex w-50 w-res-100 text-center margin-auto cursor-pointer font-20 border-radius-5"
                >
                  Lihat Detail
                  <i class="fa ml-2 mt-2 fa-arrow-right"></i>
                </router-link>
              </div>
            </div>
            <!-- <div class="col-md-6 w-res-tab-100">
              <div class="grafix-donut pt-55 pb-55">
                <div class="col-md-12 text-center">
                  <span class="font-23 font-weight-7 mb-20 d-block"
                    >DATA JIWA OAP dan Non OAP</span
                  >
                </div>

                <div class="row">
                  <div
                    class="col-md-12 shadow bg-white p-3 border-radius-5 flex mb-20"
                  >
                    <span
                      class="font-20 font-res-14 text-primary d-inline-flex justify-start margin-auto font-weight-6"
                      >Total Jiwa OAP </span
                    >
                    <span
                      class="font-20 font-res-14 text-grey d-inline-flex justify-end margin-auto font-weight-6"
                      >{{ totalForData.jiwa.oap | convertToRupiah }} Orang</span
                    >
                  </div>
                  <div
                    class="col-md-12 shadow bg-white p-3 border-radius-5 flex mb-20"
                  >
                    <span
                      class="font-20 font-res-14 text-primary d-inline-flex justify-start margin-auto font-weight-6"
                      >Total Jiwa Non OAP</span
                    >
                    <span
                      class="font-20 font-res-14 text-grey d-inline-flex justify-end margin-auto font-weight-6"
                      >{{
                       totalForData.jiwa.semua -  totalForData.jiwa.oap  | convertToRupiah
                      }}
                      Orang</span
                    > 
                  </div>
                    <div
                    class="col-md-12 shadow bg-white p-3 border-radius-5 flex mb-20"
                  >
                    <span
                      class="font-20 font-res-14 text-primary d-inline-flex justify-start margin-auto font-weight-6"
                      >Total Semua Jiwa</span
                    >
                    <span
                      class="font-20 font-res-14 text-grey d-inline-flex justify-end margin-auto font-weight-6"
                      >{{
                       totalForData.jiwa.semua   | convertToRupiah
                      }}
                      Orang</span
                    > 
                  </div>
                  <div class="col-md-12 flex mb-20">
                    <span
                      class="bg-primary pt-2 pb-2 opensans font-weight-6 text-white justify-center flex w-50 w-res-100 text-center margin-auto cursor-pointer font-20 border-radius-5"
                    >
                      Lihat Detail
                      <i class="fa ml-2 mt-2 fa-arrow-right"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </section>
      <div class="divider"></div>
      <!-- <section class="mt-20 pb-40">
        <div class="col col-md-12 text-center mb-20">
          <span class="d-block text-primary font-35 font-weight-7 roboto">INSTITUSI TERKAIT</span>
          <span class="d-block text-primary font-weight-7 font-20">Institusi terintegrasi pada platform</span>
        </div>
        <div class="d-lg-flex flex-xs-row container">
        
          <div class="col-lg-3 col-md-12   justify-center  text-center  shadow m-2 "> 
            <div class="pt-10 pb-10 pl-0 pr-0 m-13 h-100 ">
            <img class="margin-auto" src="@/assets/images/icon/kominfo.png" alt="">  
            <span class="d-block mt-3 font-weight-7 text-primary font-24 poppin">KOMINFO</span> 
            </div>
          </div>
           <div class="col-lg-3 col-md-12   justify-center  text-center shadow m-2    "> 
            <div class="pt-10 pb-10 pl-0 pr-0 m-13 ">
            <img class="margin-auto" src="@/assets/images/icon/scholl.png" alt="">  
              <span class="d-block mt-3 font-weight-7 text-primary font-24 poppin">SEKOLAH</span> 
            </div>
          </div>
           <div class="col-lg-3 col-md-12   justify-center  text-center shadow m-2   "> 
            <div class="pt-10 pb-10 pl-0 pr-0 m-13 ">
            <img class="margin-auto" src="@/assets/images/icon/desa.png" alt="">  
            <span class="d-block mt-3 font-weight-7 text-primary font-24 poppin">DESA</span> 
            </div>
          </div>
           <div class="col-lg-3 col-md-12   justify-center  text-center shadow m-2   "> 
            <div class="pt-10 pb-10 pl-0 pr-0 m-13  ">
            <img class="margin-auto" src="@/assets/images/icon/hospital.png" alt="">  
            <span class="d-block mt-3 font-weight-7 text-primary font-24 poppin">HOSPITAL</span> 
            </div>
          </div>
          
        </div>
      </section>-->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import Slick from "vue-slick";
import VueApexCharts from "vue-apexcharts";
// import GrowthChart from "./pages/dataPokok/kependudukan/components/growthChart";
// import BarDetail from "./components/BarDetail";
// import donutchart from "./pages/dataPokok/kependudukan/components/donutchart";
// import PieChart from "./components/pieChart";
import SumberAirMinum from "./pages/dataPokok/kependudukan/components/airminum";
import TingkatPendidikan from "./pages/dataPokok/kependudukan/components/TingkatPendidikan";
import BanSos from "./pages/dataPokok/kependudukan/components/bansos";
// import selecYears from "./pages/dataPokok/kependudukan/components/select-years";
import grapichpenduduk from "./pages/dataPokok/kependudukan/components/grapichpenduduk";
import BarDetail from "./pages/dataPokok/kependudukan/components/BarDetail";
export default {
  name: "home",
  components: {
    Slick,
    VueApexCharts,
    // selecYears,
    SumberAirMinum,
    TingkatPendidikan,
    BanSos,
    // donutchart,
    grapichpenduduk,
    BarDetail,
    // PieChart,
  },
  data() {
    return {
      tabnum: 1,

      totalForData: null,
      total_pria: 0,
      total_perempuan: 0,
      total_kk: 0,
      total_jiwa: 0,
      total_penduduk_pria: 0,
      news: [],
      total_penduduk_perempuan: 0,
      selected: null,
      options: [
        { value: null, text: "Data Bantuan Sosial" },
        { value: "1", text: "Data Kependuduk" },
        { value: "2", text: "Data Kesehatan" },
        { value: "3", text: "Data Bantuan Sosial" },
        { value: "4", text: "Data Keuangan" },
      ],
      selected1: null,
      options1: [{ value: null, text: "Tahun 2020" }],
      donutChart1: {
        chartOptions: {
          legend: {
            show: false,
          },
          labels: ["Pria OAP", "Perempuan OAP"],
          colors: ["#FF5306", "#1490F7"],
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: true,
                    fontSize: "22px",
                    fontFamily: "Rubik",
                    color: "#dfsda",
                    offsetY: -10,
                  },
                  value: {
                    show: true,
                    fontSize: "16px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    color: undefined,
                    offsetY: 16,
                    formatter: function(val) {
                      return val;
                    },
                  },
                  total: {
                    show: true,
                    label: "Total OAP",
                    color: "#373d3f",
                    formatter: function(w) {
                      return w.globals.seriesTotals.reduce((a, b) => {
                        return a + b;
                      }, 0);
                    },
                  },
                },
              },
            },
          },
        },
        series: [],
      },

      // chart
      lineAreaChartSpline: {
        series: [
          {
            name: "Total Distribusi",
            data: [31, 40, 28, 51, 42, 109, 20, 28, 51, 42, 10, 40],
          },
        ],

        chartOptions: {
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
          colors: ["#07980C", "#07980C"],
          xaxis: {
            categories: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
          },
          yaxis: {
            show: false,
          },

          tooltip: {
            y: {
              formatter: function(val) {
                return "Rp " + val + " 000";
              },
            },
          },
        },
      },

      slickOptions: {
        slidesToShow: 1,
        infinite: false,
        autoplay: true,
        autoplaySpeed: 5000,
        dots: false,
        arrows: false,
        centerMode: true,
        // Any other options that can be got from plugin documentation
      },
    };
  },
  mounted() {
    this.$store.commit("SET_BG", false);
    this.getTotalJenisPenduduk();
    this.getTotalPenduduk();
    this.getBerita();
  },
  methods: {
    tabbingChange(val) {
      this.tabnum = val;
    },
    getTotalPenduduk() {
      this.$axios.get("/v1/sensus/data-penduduk").then((response) => {
        let result = response.data;
        this.total_penduduk_pria = result.total_pria;
        this.total_penduduk_perempuan = result.total_wanita;
      });
    },
    getTotalJenisPenduduk() {
      this.$axios.get("/v1/sensus/jiwa-kk-pria-wanita").then((response) => {
        let result = response.data;
        this.total_pria = result.pria.semua;
        this.total_perempuan = result.wanita.semua;
        this.total_jiwa = result.jiwa.semua;
        this.total_kk = result.kartu_keluarga.semua;
        this.totalForData = result;
        this.donutChart1.series = [result.pria.oap, result.wanita.oap];
      });
    },
    getBerita() {
      this.$axios.get("/news").then((response) => {
        this.allnews = response.data;
        this.news = response.data.filter((value, index) => index < 3);
      });
    },
  },
};
</script>
