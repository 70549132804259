import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

const state = {
  repositories: [],
  repository: {},
  loading: false,
  getRepositoriesError: "",
  getRepositoryError: "",
  total: "",
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
