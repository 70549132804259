<template>
  <div>
    <div class="con-tainer-fluid bg-primary pt-10 pb-20 flex-res">
      <div class="container-standar d-lg-flex flex-xs-row">
        <div class="col-lg-3 col-md-12 mb-10  ">
          <img class="w-res-px-150" src="@/assets/images/logo.png" alt="" />
        </div>
        <div class="col-lg-3 col-md-12 mb-10   float-left">
          <span class="roboto font-weight-7 font-24 mb-5 text-white d-block">
            Tentang Kami :
          </span>
          <span class="d-block font-14 text-white font-weight-6 ln-1.5 ">
            Aplikasi SAIK+ dikembangkan oleh Pemerintah Daerah Papua Barat dalam
            rangka pelaksanaan Program Strategis Peningkatan Pembangunan Kampung
            – Otonomi Khusus (PROSPPEK OTSUS) dan dikembangkan dengan semangat
            Inovasi, Integrasi dan Kolaborasi.
          </span>
        </div>
        <div class="col-lg-3 col-md-12 col-xs-12 mb-20 float-left  ">
          <span
            class="roboto font-weight-7 font-24 mb-5 text-white d-block w-100"
          >
            Terkait :
          </span>
          <ul
            class="col-lg-10 width-100 col-md-12  mb-10  font-18 font-res-14 roboto font-weight-6 text-white ul-footer"
          >
            <li><a href="/data-kependudukan">- Data Pokok</a></li>
            <li><a href="#">- Progress Data</a></li>
            <li><a href="/layanan/administrasi-kependudukan">- Layanan</a></li>
            <li><a href="/bantuan/faq">- Bantuan</a></li>
            <li><a href="#" v-on:click="redirectToLogin">- Login</a></li>
            <li><a href="/potensi-kampung">- Potensi Kampung</a></li>
          </ul>
        </div>
        <div class="col-lg-3 mb-10 col-xs-12  col-md-12 mb-10 ">
          <span
            class="roboto font-weight-7 mb-5 font-24 text-white d-block w-100"
          >
            Hubungi Kami: :
          </span>
          <span
            class="d-block font-18 font-res-14 text-white font-weight-6 ln-1.5 "
          >
            Alamat: Jl. Brigjen Marinir (Purn) Abraham O. Atururi Arfai –
            Manokwari Provinsi Papua Barat
          </span>
          <span
            class="d-block font-18 font-res-14 text-white font-weight-6 ln-1.5 "
          >
            Phone : + 62 81248790969
          </span>
          <ul class="col-md-12   col-xs-12  flex pl-0 mt-2">
            <li class="m-2">
              <a href="#"><img src="@/assets/images/icon/fb.png" alt=""/></a>
            </li>
            <li class="m-2">
              <a href="#"><img src="@/assets/images/icon/tw.png" alt=""/></a>
            </li>
            <li class="m-2">
              <a href="#"><img src="@/assets/images/icon/ig.png" alt=""/></a>
            </li>

            <li class="m-2">
              <a href="#"><img src="@/assets/images/icon/gplus.png" alt=""/></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="col-lg-12 col-md-12 text-center  margin-auto pt-2 pb-2  bg-white"
    >
      <span class="text-primary roboto font-weight-6"
        >Hak Cipta © 2020-{{ new Date().getFullYear() }} Pemerintah Daerah Papua
        Barat</span
      >
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    redirectToLogin() {
      window.location.replace(
        "https://admin.saikplus.papuabaratprov.go.id/bakti-login"
      );
    },
  },
};
</script>
