<template>
  <div id="app"> 
    <headerVue></headerVue> 
    <breadcumb v-show="$store.state.bgSetting"></breadcumb>
    <router-view/>
    <footerVue></footerVue>
  </div>
</template>

 <script>
  import headerVue from '@/components/header.vue'
  import footerVue from '@/components/footer.vue'
  import breadcumb from '@/components/breadcumb.vue'
 export default {
   components:{
     headerVue,
     footerVue,
     breadcumb
   }
   
 }
 </script>