import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import "./registerServiceWorker";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

import vSelect from "vue-select";
Vue.component("v-select", vSelect);

// import bootstrap
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-select/dist/vue-select.css";
import "@/assets/scss/main.scss";
import "slick-carousel/slick/slick.css";
import HighchartsVue from "highcharts-vue";
Vue.use(HighchartsVue);
// font
require("./assets/css/iconfont.css");
require("./assets/css/line-awesome-font-awesome.min.css");
// import tailwind
import "@/assets/css/tailwind.css";

// Axios
import axios from "axios";
Vue.prototype.$axios = axios.create({
  baseURL: "https://admin.saikplus.papuabaratprov.go.id/api",
  timeout: 180000, // 3 menit
});

Vue.config.productionTip = false;

Vue.filter("convertToRupiah", function(angka) {
  var rupiah = "";
  var angkarev = angka
    .toString()
    .split("")
    .reverse()
    .join("");
  for (var i = 0; i < angkarev.length; i++)
    if (i % 3 == 0) rupiah += angkarev.substr(i, 3) + ".";
  return rupiah
    .split("", rupiah.length - 1)
    .reverse()
    .join("");
});

// short description
import stripHtml from "string-strip-html";
Vue.filter("shotDescription", function(value, length = 150) {
  let string = stripHtml(value);
  if (string.length > length) {
    return string.substring(0, length) + "...";
  } else {
    return string;
  }
});

// format tanggal title berita
Vue.filter("publish_date_news", function(value, type) {
  let data = value.split(" ");
  if (type == "date") {
    return data[0];
  } else {
    return data[1].substring(0, 3);
  }
});

new Vue({
  router,
  store,
  vSelect,
  render: (h) => h(App),
}).$mount("#app");
