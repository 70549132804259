import axios from "axios";

export const request = axios.create({
  baseURL: "https://admin.saikplus.papuabaratprov.go.id/api",
  timeout: 180000,
});

// request.interceptors.response.use(
//   response => {
//     return response;
//   },
//   error => {
//     const err = error.toString();
//     if (err === "Error: Request failed with status code 401") {
//       localStorage.removeItem("user-token");
//       window.location.reload();
//     }
//     return Promise.reject(error);
//   }
// );

export default request;
