import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      active: "home",
    },
  },
  {
    path: "/data-kependudukan",
    name: "data-pokok",
    component: () => import("../views/pages/dataPokok/kependudukan/data.vue"),
    meta: {
      active: "data-pokok",
    },
  },
  {
    path: "/data-bantuan-sosial",
    name: "data-bantuan-sosial",
    component: () => import("../views/pages/dataPokok/bansos/data.vue"),
    meta: {
      active: "data-pokok",
      breadcrumb: ["Home", "Data Pokok", "Data Bantuan Sosial"],
    },
  },
  {
    path: "/data-pendidikan",
    name: "data-pendidikan",
    component: () => import("../views/pages/dataPokok/pendidikan/data.vue"),
    meta: {
      active: "data-pokok",
      breadcrumb: ["Home", "Data Pokok", "Data Pendidikan"],
    },
  },
  {
    path: "/data-kesehatan",
    name: "data-kesehatan",
    component: () => import("../views/pages/dataPokok/kesehatan/data.vue"),
    meta: {
      active: "data-pokok",
      breadcrumb: ["Home", "Data Pokok", "Data Kesehatan"],
    },
  },
  {
    path: "/data-kabupaten/:id",
    name: "data-kabupaten",
    component: () =>
      import("../views/pages/dataPokok/kependudukan/data-kabupaten.vue"),
    meta: {
      active: "data-pokok",
    },
  },
  {
    path: "/data-desa/:id",
    name: "data-desa",
    component: () =>
      import("../views/pages/dataPokok/kependudukan/data-desa.vue"),
    meta: {
      active: "data-pokok",
    },
  },
  {
    path: "/detail-provinsi",
    name: "detail-provinsi",
    component: () =>
      import("../views/pages/dataPokok/kependudukan/detail-provinsi.vue"),
    meta: {
      active: "data-pokok",
    },
  },
  {
    path: "/layanan/administrasi-kependudukan",
    name: "administrasi-kependudukan",
    component: () =>
      import("../views/pages/layanan/administrasi-kependudukan.vue"),
    meta: {
      active: "administrasi-kependudukan",
    },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/About.vue"),
    meta: {
      active: "data-pokok",
    },
  },
  {
    path: "/berita",
    name: "Berita",
    component: () => import("../views/pages/berita/list.vue"),
    meta: {
      active: "berita",
    },
  },
  {
    path: "/berita/:id",
    name: "Detail Berita",
    component: () => import("../views/pages/berita/detail.vue"),
    meta: {
      active: "berita",
    },
  },
  {
    path: "/repository",
    name: "Repository",
    component: () => import("../views/pages/repository/index.vue"),
    meta: {
      active: "repository",
    },
  },
  {
    path: "/repository/:id",
    name: "Detail Repository",
    component: () => import("../views/pages/repository/detail.vue"),
    meta: {
      active: "repository",
    },
  },
  {
    path: "/potensi-kampung",
    name: "Potensi Kampung",
    component: () => import("../views/pages/potensi-kampung/home.vue"),
    meta: {
      active: "potensi-kampung",
    },
  },
  {
    path: "/potensi-kampung/:id",
    name: "Detail Potensi Kampung",
    component: () => import("../views/pages/potensi-kampung/detail.vue"),
    meta: {
      active: "potensi-kampung",
    },
  },
  {
    path: "/bantuan/istilahsaik",
    name: "Istilah dalam SAIK",
    component: () => import("../views/pages/bantuan/istilahsaik.vue"),
    meta: {
      active: "bantuan",
    },
  },
  {
    path: "/bantuan/faq",
    name: "FAQ",
    component: () => import("../views/pages/bantuan/faq.vue"),
    meta: {
      active: "bantuan",
    },
  },
  {
    path: "/bantuan/tentangkami",
    name: "tentang kami",
    component: () => import("../views/pages/bantuan/tentangkami.vue"),
    meta: {
      active: "bantuan",
    },
  },
  {
    path: "/bantuan/dokumentasi",
    name: "dokumentasi",
    component: () => import("../views/pages/bantuan/dokumentasi.vue"),
    meta: {
      active: "bantuan",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
