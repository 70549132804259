import request from "@/utils/axios";

const getRepositoryList = ({ commit }, page) => {
  return new Promise((resolve, reject) => {
    commit("SET_LOADING");
    const params = {
      page: page,
    };
    request
      .get("/document", null, { params })
      .then((result) => {
        commit("SET_REPOSITORY_LIST", result.data);
        resolve(result);
      })
      .catch((error) => {
        commit("GET_REPOSITORY_LIST_ERROR", error);
        reject("error");
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  });
};

const filterRepositoryList = ({ commit }, { form, page }) => {
  return new Promise((resolve, reject) => {
    commit("SET_LOADING");
    const params = {
      page: page,
      sektor: form.sektor,
      jenis_dokumen: form.jenis_dokumen,
      id_format_dokument: form.id_format_dokument
    };
    request
      .get("/data-search", { params })
      .then((result) => {
        commit("SET_REPOSITORY_LIST", result.data);
        resolve(result);
      })
      .catch((error) => {
        commit("GET_REPOSITORY_LIST_ERROR", error);
        reject("error");
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  });
};

const getRepositoryById = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    commit("SET_LOADING");
    request
      .get("/document/" + id)
      .then((result) => {
        commit("SET_REPOSITORY", result.data);
        resolve(result);
      })
      .catch((error) => {
        commit("GET_REPOSITORY_ERROR", error);
        reject(error);
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  });
};

export default {
  getRepositoryList,
  filterRepositoryList,
  getRepositoryById,
};
