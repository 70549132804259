<template>
  <div style="background:white;" class="fixed-content">
    <div class="comtainer-fluid bg-primary pt-2 pb-2 pb-res-20">
      <div class="container-nav-saik">
        <div class="img-logo-header" @click="routerPush()">
          <img class="cursor-pointer" src="@/assets/images/logo.png" alt />
        </div>
        <b-nav-item-dropdown class="nav-top-borderless" right>
          <template v-slot:button-content>
            <span class="btn-top">
              <span class="img-top-img ">
                <img src="@/assets/images/icon/icon-question.png" alt />
              </span>
              Bantuan
            </span>
          </template>
          <!-- <b-dropdown-item href="/bantuan/istilahsaik">Istilah dalam SAIK</b-dropdown-item> -->
          <b-dropdown-item href="/bantuan/dokumentasi"
            >Dokumentasi SAIK</b-dropdown-item
          >
          <b-dropdown-item href="#">Troubleshoot</b-dropdown-item>
          <b-dropdown-item href="/bantuan/faq">FAQ</b-dropdown-item>
          <!-- <b-dropdown-item href="#">Helpdesk</b-dropdown-item> -->
          <b-dropdown-item href="/bantuan/tentangkami"
            >Tentang Kami</b-dropdown-item
          >
        </b-nav-item-dropdown>
        <b-nav-item class="nav-top" href="#">
          <b-navbar-brand
            class="mr-0 text-white pt-0 pb-0 font-res-16"
            href="#"
            v-on:click="redirectToLogin"
            >Login</b-navbar-brand
          >
        </b-nav-item>
      </div>
    </div>

    <div class="container-fluid shadow">
      <div class="container-normal ml-res-5  mr-res-5">
        <div class="margin-auto width-100 bottom-navigation">
          <b-navbar toggleable="lg" type="dark">
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
              <!-- Right aligned nav items -->
              <b-navbar-nav class="ml-auto sub-nav  " right>
                <b-nav-item
                  class=""
                  v-bind:class="{ 'is-active': activeNav == 'home' }"
                >
                  <b-navbar-brand class="mr-2 ml-2" to="/">Home</b-navbar-brand>
                </b-nav-item>
                <b-nav-item
                  v-bind:class="{ 'is-active': activeNav == 'berita' }"
                >
                  <b-navbar-brand class="mr-2 ml-2" to="/berita"
                    >Berita</b-navbar-brand
                  >
                </b-nav-item>

                <!-- <b-nav-item-dropdown class right>
                  <template v-slot:button-content class="tittle-nav">
                    <b-navbar-brand href="#">Unduhan</b-navbar-brand>
                  </template>
                  <b-dropdown-item href="#">Dokumen Manual</b-dropdown-item>
                  <b-dropdown-item href="#">Istilah dalam SAIK</b-dropdown-item>
                  <b-dropdown-item href="#">Dokumentasi SAIK</b-dropdown-item>
                </b-nav-item-dropdown> -->

                <b-nav-item-dropdown
                  right
                  v-bind:class="{ 'is-active': subchild === true }"
                >
                  <template v-slot:button-content class="tittle-nav">
                    <b-navbar-brand>Data Pokok</b-navbar-brand>
                  </template>
                  <b-dropdown-item to="/data-kependudukan"
                    >Data Kependudukan</b-dropdown-item
                  >
                  <b-dropdown-item to="/data-kesehatan"
                    >Data Kesehatan</b-dropdown-item
                  >
                  <b-dropdown-item to="/data-pendidikan"
                    >Data Pendidikan</b-dropdown-item
                  >
                  <b-dropdown-item to="/data-bantuan-sosial"
                    >Data Bantuan Sosial</b-dropdown-item
                  >
                  <!-- <b-dropdown-item href="#">Data Keuangan</b-dropdown-item> -->
                </b-nav-item-dropdown>
                <!-- <b-nav-item-dropdown class right>
                  <template v-slot:button-content class="tittle-nav">
                    <b-navbar-brand href="#">Progres Data</b-navbar-brand>
                  </template>
                  <b-dropdown-item href="#">Progres Data Kependudukan</b-dropdown-item>
                  <b-dropdown-item href="#">Progres Data Kesehatan</b-dropdown-item>
                  <b-dropdown-item href="#">Progres Data Pendidikan</b-dropdown-item>
                  <b-dropdown-item href="#">Progres Data Bantuan Sosial</b-dropdown-item>
                  <b-dropdown-item href="#">Progres Data Keuangan</b-dropdown-item>
                </b-nav-item-dropdown> -->

                <b-nav-item
                  v-bind:class="{ 'is-active': activeNav == 'repository' }"
                >
                  <b-navbar-brand class="mr-2 ml-2" to="/repository"
                    >Repository</b-navbar-brand
                  >
                </b-nav-item>

                <b-nav-item
                  v-bind:class="{ 'is-active': activeNav == 'potensi-kampung' }"
                >
                  <b-navbar-brand class="mr-2 ml-2" to="/potensi-kampung"
                    >Potensi Kampung</b-navbar-brand
                  >
                </b-nav-item>
                <b-nav-item-dropdown
                  right
                  v-bind:class="{ 'is-active': subchild2 === true }"
                >
                  <template v-slot:button-content class="tittle-nav">
                    <b-navbar-brand>Layanan</b-navbar-brand>
                  </template>
                  <b-dropdown-item to="/layanan/administrasi-kependudukan"
                    >Administrasi Kependudukan</b-dropdown-item
                  >
                  <!-- <b-dropdown-item href="#">Akta Lahir</b-dropdown-item>
                  <b-dropdown-item href="#">Akta Nikah</b-dropdown-item>
                  <b-dropdown-item href="#">Akta Kematian</b-dropdown-item> -->
                  <b-dropdown-item href="#">Mutasi</b-dropdown-item>
                  <b-dropdown-item href="#">Pertanahan</b-dropdown-item>
                  <b-dropdown-item
                    href="https://admin.saikplus.papuabaratprov.go.id/admin/letter/new"
                    >Surat Menyurat</b-dropdown-item
                  >
                </b-nav-item-dropdown>
              </b-navbar-nav>
            </b-collapse>
          </b-navbar>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      SubBackground: false,
      activeNav: null,
      subchild: false,
      subchild2: false,
    };
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      if (to.meta !== undefined) {
        this.activeNav = to.meta.active;
        if (this.activeNav === "data-pokok") {
          this.subchild = true;
        }
        if (this.activeNav === "administrasi-kependudukan") {
          this.subchild = false;
          this.subchild2 = true;
        } else {
          this.subchild2 = false;
          this.subchild = false;
        }
      }
      next();
    });
  },
  methods: {
    routerPush() {
      this.$router.push("/");
    },
    redirectToLogin() {
      window.location.replace(
        "https://admin.saikplus.papuabaratprov.go.id/bakti-login"
      );
    },
  },
};
</script>
