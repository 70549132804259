const SET_REPOSITORY_LIST = (state, payload) => {
  state.repositories = payload.data;
  state.total = payload.data.total;
};

const SET_REPOSITORY = (state, payload) => {
  state.repository = payload.data[0];
};

const SET_LOADING = (state, payload = true) => {
  state.loading = payload;
};

const GET_REPOSITORY_LIST_ERROR = (state, payload) => {
  state.getRepositoriesError = payload;
};

const GET_REPOSITORY_ERROR = (state, payload) => {
  state.getRepositoryError = payload;
};

export default {
  SET_REPOSITORY_LIST,
  SET_REPOSITORY,
  SET_LOADING,
  GET_REPOSITORY_LIST_ERROR,
  GET_REPOSITORY_ERROR,
};
